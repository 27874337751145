<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="tanggal" label="Tanggal" style="margin-bottom: 0;">
            <a-date-picker
              v-model:value="formState.tanggal"
              placeholder="Pilih Tanggal"
              format="DD-MM-YYYY"
              valueFormat="YYYY-MM-DD"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item name="judul" label="Judul">
            <a-input v-model:value="formState.judul" placeholder="Masukkan Judul" />
          </a-form-item>
          <a-form-item name="nomor" label="Nomor">
            <a-input v-model:value="formState.nomor" placeholder="Masukkan Nomor" />
          </a-form-item>
          <a-form-item name="files" label="File">
            <a-upload :file-list="formState.fileList" :remove="handleRemove" :before-upload="beforeUpload">
              <a-button> <a-icon type="upload" /> Select File </a-button>
            </a-upload>
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/eksternal/akta-risalah">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const AktarRisalahForm = {
  name: 'AktarRisalahForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();

    const formState = reactive({
      tanggal: dateObj.getUTCFullYear() + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2),
      judul: '',
      nomor: '',
      fileList: [],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = values => {
      const formData = new FormData();
      formState.fileList.forEach(file => {
        if (!file.old) {
          formData.append('files[]', file);
        } else {
          formData.append('oldFiles[]', file.name);
        }
      });

      Object.keys(values).forEach(key => {
        if (key != 'files') formData.append(key, values[key]);
      });

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'akta-risalah-rups',
          data: formData,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosCrudSubmitData2', {
          id: params.id,
          url: 'akta-risalah-rups',
          data: formData,
        });
      }
    };

    const rules = {
      tanggal: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
      judul: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
      nomor: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const beforeUpload = file => {
      console.log(file);
      formState.fileList = [...formState.fileList, file];
      return false;
    };

    const handleRemove = file => {
      const index = formState.fileList.indexOf(file);
      const newFileList = formState.fileList.slice();
      newFileList.splice(index, 1);
      formState.fileList = newFileList;
    };

    onMounted(() => {
      if (props.mode == 'Ubah') {
        const data = {
          url: 'akta-risalah-rups',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });

          crud.value.akta_risalah_rups_files.forEach(file => {
            formState.fileList.push({ name: file.filename, uid: file.id, old: true });
          });
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      beforeUpload,
      handleRemove,
    };
  },
};

export default AktarRisalahForm;
</script>
