<template>
  <AktarRisalahForm mode="Tambah" module="Akta dan Risalah RUPS"> </AktarRisalahForm>
</template>

<script>
import AktarRisalahForm from './form';

const AktarRisalahAdd = {
  name: 'AktarRisalahAdd',
  components: { AktarRisalahForm },
};

export default AktarRisalahAdd;
</script>
